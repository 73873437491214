
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import {
        CreateOrganizationParams,
        IAccountResponse,
        IInvitation,
        IOrganization,
        IOrganizationType,
        IPlaceSuggestion,
    } from '@/types';

    import {formHelper} from '@/helpers';

    import PlaceInput from '@/components/forms/PlaceInput.vue';
    import OrganizationAutocomplete from '@/components/forms/OrganizationAutocomplete.vue';

    import {OrganizationModule, SnackbarModule} from '@/store/modules';

    const organizationNamespace = namespace('organization');
    const registrationNamespace = namespace('registration');

    @Component<LinkOrganization>({
        components: {
            PlaceInput,
            OrganizationAutocomplete,
        },
    })
    export default class LinkOrganization extends Vue {
        public newLocation: IPlaceSuggestion|null = null;
        public newOrganization: IOrganization|null = null;
        public showCreateForm: boolean = false;

        public createForm: boolean = false;
        public createName: string|null = null;
        public createLocation: IPlaceSuggestion|null = null;
        public createType: IOrganizationType|null = null;

        public confirmCreateDialog: boolean = false;
        public sendingCreateOrganizationRequest: boolean = false;
        public organizationToCreate: CreateOrganizationParams|null = null;

        public addressRules = formHelper.getOrganizationAddressRules();
        public nameRules = formHelper.getOrganizationNameRules();
        public typeRules = formHelper.getOrganizationTypeRules();

        public isSendingJoinRequest: boolean = false;

        @organizationNamespace.Getter('typesList')
        public typesList!: IOrganizationType[];

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization|null;

        @registrationNamespace.Getter('invitation')
        public invitation!: IInvitation|null;

        public sendJoinRequest(organization: IOrganization) {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.isSendingJoinRequest = true;

            this.$api
                .organization
                .createjoinRequest(organization.id)
                .then(() => {
                    snackbarModule
                        .displaySuccess(
                            `Votre demande a bien été envoyée.<br>
                            L'administrateur de l'établissement dispose de 7 jours pour répondre<br>
                            Vous serez notifié par email de sa réponse`,
                        )
                    ;

                    this.$emit('join-request-created');
                })
                .catch((error) => {
                    if (error.response.data.error === 'account_role_exists') {
                        snackbarModule.displayError('Vous avez déjà rejoint cet établissement');
                    } else if (error.response.data.error === 'organization_join_request_exists') {
                        snackbarModule.displayError('Vous avez déjà demandé à rejoindre cet établissement');
                    } else {
                        snackbarModule.displayError();
                    }

                })
                .finally(() => this.isSendingJoinRequest = false)
            ;
        }

        public createOrganization(organization: CreateOrganizationParams) {
            this.organizationToCreate = organization;
            this.confirmCreateDialog = true;
        }

        public createNewOrganization() {
            this.createOrganization({
                name: this.createName as string,
                place_id: (this.createLocation as IPlaceSuggestion).raw_data.id,
                organization_type_id: (this.createType as IOrganizationType).id,
            });
        }

        public sendCreateOrganizationRequest() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.sendingCreateOrganizationRequest = true;

            getModule(OrganizationModule, this.$store)
                .create(this.organizationToCreate as CreateOrganizationParams)
                .then((response: IAccountResponse) => {
                    if (
                        this.loggedOrganization &&
                        this.loggedOrganization.id === (response.organization as IOrganization).id
                    ) {
                        snackbarModule.displaySuccess(
                            `Votre établissement a bien été créé.<br>
                            Vous êtes désormais connecté avec cet établissement`,
                        );
                    } else {
                        snackbarModule.displaySuccess(
                            `Votre établissement a bien été créé.`,
                        );
                    }

                    this.$emit('organization-created', response.organization);
                    this.confirmCreateDialog = false;
                    this.sendingCreateOrganizationRequest = false;
                })
                .catch(() => snackbarModule.displayError())
                .finally(() => {
                    this.confirmCreateDialog = false;
                    this.sendingCreateOrganizationRequest = false;
                })
            ;
        }

        public created() {
            if (this.invitation?.organization) {
                const organization = this.invitation.organization;
                this.newLocation = {
                    title: organization.address.label,
                    raw_data: {
                        id: '',
                    },
                };
                this.newOrganization = organization;
            }
        }
    }
